:root {
    --e-global-color-primary: #6EC1E4;
    --e-global-color-secondary: #54595F;
    --e-global-color-text: #7A7A7A;
    --e-global-color-accent: #61CE70;
    --e-global-color-9810be5: #04151F;
    --e-global-color-0cf0dd7: #3D474D;
    --e-global-color-ef3d1cf: #6D7275;
    --e-global-color-a1c1d08: #8A9499;
    --e-global-color-da47cd2: #B2BBC0;
    --e-global-color-f217491: #E8EAEB;
    --e-global-color-de8b3de: #FFFFFF;
    --e-global-color-1575607: #092D2C;
    --e-global-color-e1aaa25: #0D3F3E;
    --e-global-color-926b68c: #17706E;
    --e-global-color-a32e03a: #2E7E7D;
    --e-global-color-0050ea6: #1E9491;
    --e-global-color-10d1136: #5D9B9A;
    --e-global-color-7c2e467: #8BB8B7;
    --e-global-color-63862e3: #B9D4D4;
    --e-global-color-feef51c: #D7E4E7;
    --e-global-color-3087115: #F4F8F9;
    --e-global-color-14f27bb: #FB7813;
    --e-global-color-c202ffc: #FB862B;
    --e-global-color-13d3a78: #FDBC89;
    --e-global-color-20c1105: #E8F1F1;
    --e-global-color-1065a98: #E8F1F1;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
    --e-global-typography-092f863-font-family: "Avenir Next";
    --e-global-typography-092f863-font-size: 57px;
    --e-global-typography-092f863-font-weight: bold;
    --e-global-typography-092f863-line-height: 1.1em;
    --e-global-typography-36f1e06-font-family: "Avenir Next";
    --e-global-typography-36f1e06-font-size: 43px;
    --e-global-typography-36f1e06-font-weight: bold;
    --e-global-typography-36f1e06-line-height: 51.6px;
    --e-global-typography-e904290-font-family: "Avenir Next";
    --e-global-typography-e904290-font-size: 32px;
    --e-global-typography-a153874-font-family: "Open Sans";
    --e-global-typography-a153874-font-size: 18px;
    --e-global-typography-a153874-line-height: 28.8px;
    --e-global-typography-812ff4e-font-family: "Avenir Next";
    --e-global-typography-812ff4e-font-size: 18px;
    --e-global-typography-812ff4e-font-weight: 600;
    --e-global-typography-793e792-font-family: "Avenir Next";
    --e-global-typography-793e792-font-size: 24px;
    --e-global-typography-793e792-font-weight: 600;
    --e-global-typography-793e792-line-height: 38.4px;
    --e-global-typography-36bfc14-font-family: "Avenir Next";
    --e-global-typography-36bfc14-font-size: 18px;
    --e-global-typography-36bfc14-font-weight: 400;
    --e-global-typography-36bfc14-line-height: 28.8px;
    --e-global-typography-7260d34-font-family: "Avenir Next";
    --e-global-typography-7260d34-font-size: 18px;
    --e-global-typography-7260d34-font-weight: 400;
    --e-global-typography-7260d34-line-height: 28.8px;
    color: var( --e-global-color-1575607 );
}

.custom-theme{
    font-family: "Open Sans", Sans-serif;
    color: var( --e-global-color-a1c1d08 ) !important;
}


/*body.custom-theme fuse-splash-screen{
    background-color: rgba(76,86,92, 0.65);
}

body.custom-theme fuse-splash-screen .spinner > div{
    background-color: var(--e-global-color-14f27bb);
}*/
.custom-theme .text-4xl{
    color: var( --e-global-color-e1aaa25 );
    font-family: "Avenir Next", Sans-serif;
    font-weight: bold;
    line-height: 68.4px;
}

/*
    button
*/
.custom-theme button.bg-accent{
    background-color: #F4F8F9 !important;
}

.custom-theme button.bg-primary, .custom-theme button.mat-primary{
    font-family: "Avenir Next", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1em !important;
    fill: var( --e-global-color-de8b3de );
    color: var( --e-global-color-de8b3de ) !important;
    background-color: var( --e-global-color-14f27bb ) !important;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var( --e-global-color-14f27bb );
    border-radius: 12px 12px 12px 12px;
    padding: 25px 40px 25px 40px;
}

.custom-theme.light .mat-flat-button{
    background-color: var( --e-global-color-14f27bb ) !important;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var( --e-global-color-14f27bb );
    border-radius: 12px 12px 12px 12px;
}

.custom-theme button.mat-primary, .custom-theme.light .mat-flat-button{
    color: #FB7813 !important;
    background-color: #FFFFFF !important;
}


.custom-theme button.bg-primary:hover {
    color: #FB7813 !important;
    background-color: #FFFFFF !important;
}

.custom-theme button.mat-primary:hover, .custom-theme.light .mat-flat-button:hover {
    color: var( --e-global-color-de8b3de ) !important;
    background-color: var( --e-global-color-14f27bb ) !important;
}


.custom-theme button.mat-button-disabled.mat-primary{
    border-color: #FFFFFF !important;
}
.custom-theme button.mat-button-disabled.mat-primary:hover{
    color: var( --e-global-color-de8b3de ) !important;
    background-color: #ffffff !important;
    border-color: #FFFFFF !important;
}




.custom-theme mat-row{
    padding: 15px 40px 15px 40px;
}

/*.custom-theme .mat-flat-button .mat-button-focus-overlay, .custom-theme .mat-raised-button .mat-button-focus-overlay, .custom-theme .mat-fab .mat-button-focus-overlay, .custom-theme .mat-mini-fab .mat-button-focus-overlay {
    --tw-bg-opacity: 1 !important;
    background-color: #fff !important;
    --tw-bg-opacity: 0.2 !important;
}*/

.custom-theme .mat-icon.text-primary{
    color: var( --e-global-color-14f27bb ) !important;
}


/*menu left*/
.custom-theme fuse-vertical-navigation{
    background-image: linear-gradient(
        180deg, #1E9491 0%, #0D3F3E 100%);
}

/*.custom-theme .dark.bg-primary{
    background-color: #1e9390 !important;
}*/

.custom-theme .dark.bg-primary {
    background-color: #f1f5f9 !important;
    color: var( --e-global-color-e1aaa25 ) !important;
    text-align: center;
    font-family: "Avenir Next", Sans-serif;
    font-size: 57px;
    font-weight: bold;
    line-height: 68.4px;
}

    /*detail service card*/
.custom-theme .card-double-color{
    background-color: #f4f8f9;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    padding: 10px 0px;
}

.custom-theme .performances-container{
    position: relative;
}

.custom-theme .performances-container button{
    margin-bottom: 10px;
    /*position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;*/
}

.custom-theme .performances-card-img{
    height: 200px;
    text-align: center;
    display: block;
    margin: 0 auto 25px auto;
    text-align: center;
    width: 85%;
}

.custom-theme .performances-card-img img{
    display: block;
    height: 100%;
    object-fit: contain;
    margin: 10px 0px;
}

.performances-container .text-2xl{
    color: var( --e-global-color-e1aaa25 );
    font-family: "Avenir Next", Sans-serif;
    font-size: 32px;
    line-height: 1.3em;
    margin-bottom: 10px;
}


/*.custom-theme .text-lg{
    font-size: 1.4rem !important;
}*/
.custom-theme .custom-card-login, .custom-theme .custom-card-signup{
    background-color: #f4f8f9 !important;
}
.custom-theme .home-description-container h1{
    color: var( --e-global-color-e1aaa25 );
    font-family: "Avenir Next", Sans-serif;
    font-size: 40px !important;
    font-weight: bold;
    line-height: 68.4px;
    text-align: center;
    padding: 10px 30px;
}


.custom-theme .title-card-md{
    color: var( --e-global-color-e1aaa25 );
    font-family: "Avenir Next", Sans-serif;
    font-size: 30px !important;
    font-weight: bold;
    text-align: center;
    padding: 10px 20px;
}

.custom-theme button.custom-header-user-btn{
    font-family: "Avenir Next", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    fill: var( --e-global-color-de8b3de );
    color: var( --e-global-color-de8b3de );
    background-color: transparent;
    background-image: linear-gradient(
        90deg, var( --e-global-color-13d3a78 ) 0%, var( --e-global-color-14f27bb ) 100%);
    border-radius: 12px 12px 12px 12px;
    box-shadow: 0px 6px 20px 0px rgb(251 120 19 / 40%);
    padding: 12px 35px 12px 35px;
    width: 100px;
}

.custom-theme .user-header-container div{
    padding: 10px;
}

/*home*/
/*
.custom-theme .home-footer-container{
    background-color: transparent;
    background-image: linear-gradient(
        180deg, #ffffff 0%, var( --e-global-color-e1aaa25 ) 100%);
    height: 150px;
    bottom: 0;
    position: fixed;
    width: 100%;
}

.custom-theme .home-footer-container .img-container{
    position: absolute;
    width: 60%;
    margin: 0 20%;
    top: -80px;
}
*/
.custom-theme .home-footer-container .img-container {
    width: 50%;
    margin: 2% 25%;
}

.custom-theme .text-left{
    text-align: left;
}

.custom-theme .bg-primary .flex.items-center{
    text-align: right;
    display: block !important;
    padding-right: 3%;
}

.custom-theme .custom-logo-home{
    position: relative;
    background-color: #f4f8f9;
    height: 150px;
    margin-top: 0px !important;
}

.custom-theme .custom-logo-home img{
    margin: auto !important;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.custom-theme .little-subtitle{
    font-size: 16px;
    font-style: italic;
}

.custom-theme .custom-line-through{
    text-decoration: line-through;
    color: #cecece;
}

.custom-theme mat-dialog-content, .custom-theme mat-dialog-container{
    overflow: visible;
}

.custom-theme .p-button{
    color: #FB7813 !important;
    background-color: #FFFFFF !important;
    border-color: #FB7813 !important;
}
.custom-theme .p-button:focus {
    box-shadow: 0 0 0 0.2rem #FB7813 !important;
}

.custom-theme .home-description-container h1.text-email{
    font-size: x-large !important;
}
