/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.mat-form-field.mat-form-field-invalid {
    .mat-form-field-outline >*{
        border-color:var(--fuse-warn);
    }
}


/* Form error */
mat-form-field.mat-form-field-invalid  .mat-form-field-wrapper{
    padding-bottom: 5px;
}
mat-error{
    min-height: 21px;
    padding-bottom: 15px;
}

.logo {
    max-height: 88px;
    width:auto;
    margin: 0.5rem 0;
}

.mat-paginator .mat-paginator-container{justify-content: right;}

page-content{width:100%;}

.mat-form-field.mat-form-field-slide .mat-form-field-wrapper .mat-form-field-flex{
    border:none !important;
    box-shadow: none !important;
}




/* Spinner container */
.spinner-container{background-color:rgba(0,0,0, 0.7);display: flex; justify-content: center;align-items: center;position:fixed;top:0;bottom:0;left:0;right:0;z-index:999999;}
.spinner-container {
    .mat-progress-spinner circle, .mat-spinner circle{stroke:#fff !important}
}

/* Expansion Panel */
.mat-accordion  .mat-expansion-panel {
        margin: 0;
        border-radius: 0px !important;
}
.mat-accordion  .mat-expansion-panel.mat-expanded {
    margin: 16px 0;
}

input-slide .mat-form-field-flex{padding: 0 !important;}

//Joyride
.joyride-step__holder{
    width: 400px;
    @media (min-width:960px) {
        width: 450px;
    }
    @media (min-width:1280px) {
        width: 500px;
    }
    @media (min-width:1440px) {
        width: 550px;
    }
}

.bottom1{
    position: absolute;
    bottom: 100px;
    width: 91%;
}

.bottom2{
    position: absolute;
    bottom: 50px;
    width: 91%;
}

.bottom3{
    position: absolute;
    bottom: 0;
    width: 91%;
}

.new-subscription-img-card img{
    padding: 5% !important;
}

.subscriptions-list-container p.date-not-active {
    color: red;
}

.subscriptions-list-container p.date-is-active {
    color: green;
}

/*#joyride-step-profile{
    top:0px !important;
    right: 0px !important;
}*/

#cdk-overlay-0{
    top: 0px !important;
    right: 0px !important;
}

#mat-menu-panel-0{
    transform-origin: right top !important;
}

.custom-theme .country-profile-field .p-dropdown{
    width: 100% !important;
    padding: 0px 6px;
}
.custom-theme .country-profile-field{
    padding: 10px 0px 20px 0px;
}

.custom-theme .text-ellipsis{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.custom-theme .container-file-bg{
    background-color: #fdba85 !important;
}

.custom-theme .text-white{
    color: #FFFFFF !important;
}
//cdk-overlay-connected-position-bounding-box

.custom-theme .text-red{
    color: red;
}

/*.custom-theme .global-toast .p-toast-bottom-center{
    z-index: 10000;
    bottom: 100px;
}*/

.custom-theme .info-text{
    color: #FB7813 !important;
}
